<template>
  <!-- :value="row.simSlots"
:id="row.n_id"
<span class="spanwithspace" onmouseover="getRemoteTooltip(this,'',{ 'function':'getRemotePopupInfo', 'index':{$id} },'','','','serve.php?f=configuration&f2=ratSimManagement&f3=simSlots','1000px');" onclick="getDataTableDialog(this,'serve.php?f=configuration&f2=ratSimManagement&f3=simSlots',{$id},{ width: 1000});" title="test"><a href="javascript:void(0)">{$value["fullCount"]}/{$value["totalCount"]}</a></span> -->

  <remoteTooltip
    path="serve.php?f=configuration&f2=ratSimManagement&f3=simSlots"
    :additionalAttributes="{ function: 'getRemotePopupInfo', index: row.n_id }"
    :dialogAttributes="{}"
  >
    <addField
      :path="'serve.php?f=configuration&f2=ratSimManagement&f3=simSlots'"
      :additionalAttributes="{ index: row.n_id }"
      :dialogAttributes="{}"
      v-on="$listeners"
      :showDialog="openDialog"
      @dialog-closed="openDialog = false"
    >
      {$value["fullCount"]}/{$value["totalCount"]}
    </addField>
  </remoteTooltip>
</template>
<script>
import remoteTooltip from "@/commonComponents/remoteTooltip.vue";
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField, remoteTooltip },
  props: ["row"],
  data() {
    return { openDialog: false };
  },
  computed: {
    value() {
      return this.row.simSlots;
    },
    id() {
      return this.row.n_id;
    },
  },
};
</script>